<template>
  <custom-dialog :dialog="dialog" @outsideClick="$emit('update:dialog', false)">
    <DialogTitle :title="$vuetify.lang.t('$vuetify.stockEdit')" />

    <v-card-text class="pb-0">
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col cols="3" align-self="center" class="pt-0">
              {{ $vuetify.lang.t("$vuetify.salesSubject") }}
            </v-col>

            <v-col cols="9" class="pt-0">
              <v-checkbox
                v-model="saleEntity.value"
                :label="saleEntity.text"
                disabled
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" lg="3" md="3" sm="4" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.warehouseId')"
                v-model="myObj.code"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" lg="9" md="9" sm="8" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.warehouseName')"
                v-model="myObj.name"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.Controller')"
                v-model="myObj.leader"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.contactPerson')"
                v-model="myObj.leader"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.contactIphone')"
                v-model.number="myObj.mobile"
                type="number"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" class="py-0">
              <v-text-field
                outlined
                :label="$vuetify.lang.t('$vuetify.addr')"
                v-model="myObj.address"
                :rules="generalRule"
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-textarea
                :label="$vuetify.lang.t('$vuetify.remarks')"
                v-model="myObj.note"
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="pb-8">
      <v-spacer />

      <v-btn color="red darken-2" outlined @click="cancelForm">
        {{ $vuetify.lang.t("$vuetify.cancel") }}
      </v-btn>

      <v-btn color="blue darken-2" @click="validate">
        {{ $vuetify.lang.t("$vuetify.confirm") }}
      </v-btn>

      <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
    </v-card-actions>
  </custom-dialog>
</template>

<script>
import { sellList } from "@/api/basicdata";

export default {
  name: "stockInCRUD",
  props: { dialog: Boolean, myObj: Object },
  data() {
    return {
      valid: false,
      generalRule: [(v) => !!v || "this field is required"],
      saleEntity: { id: "", text: "A9 Online KH Co., LTD", value: true },
      sellListData: [],
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialog", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    validate() {
      this.myObj.sell_company = this.saleEntity.id;
      this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    },
    getSellList() {
      sellList()
        .then((data) => {
          this.sellListData = [...data.items];

          this.saleEntity.id = this.sellListData[0].id; //5f93a695b83cc357e9cb6773
        })
        .catch((err) => {
          console.log(err);
          this.tableLoading = false;
        });
    },
  },
  watch: {
    dialog() {
      this.getSellList();
    },
  },
};
</script>